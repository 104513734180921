import axios from '@/api/config/interceptor'
import {
    host,
    fileUpload
} from '@/api/config/host'
import {
    _paramsToQueryString
} from '@/utils/utils'

// 导出临登表格
export const DriverTaxDeclarationAddedTaxExportAsync = (params, IsPersonalTax) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DriverTaxDeclaration/DriverTaxDeclarationAddedTaxExportAsync/${IsPersonalTax}`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
import axios from '@/api/config/interceptor'
import {
    host,
    fileUpload
} from '@/api/config/host'
import {
    _paramsToQueryString
} from '@/utils/utils'


// 获取临登任务列表
export const DriverTaxDeclaration = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DriverTaxDeclaration/DriverTaxDeclaration`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 导出临登表格
export const DriverTaxDeclarationExportAsync = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DriverTaxDeclaration/DriverTaxDeclarationExportAsync`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 导入临登表格
export const DriverTaxDeclarationImportAsync = (isReg, params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DriverTaxDeclaration/DriverTaxDeclarationImportAsync/${isReg}`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}